import PropTypes from 'prop-types';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { withTranslation } from 'react-i18next';

const ConfirmModal = ({ open, handleCancel, handleSuccess, message, t }) => {
    return (
        <Modal isOpen={open}>
            <ModalHeader>{t('CONFIRM_DELETE_MODAL.TITLE')}</ModalHeader>

            <ModalBody>
                {typeof message === 'function' ? message() : message}
            </ModalBody>

            <ModalFooter>
                <button onClick={handleCancel} className="btn btn-dark btn-sm">
                    {t('CONFIRM_DELETE_MODAL.CANCEL_BUTTON')}
                </button>

                <button
                    onClick={handleSuccess}
                    className="btn btn-danger btn-sm"
                >
                    {t('CONFIRM_DELETE_MODAL.CONFIRM_BUTTON')}
                </button>
            </ModalFooter>
        </Modal>
    );
};

ConfirmModal.propTypes = {
    open: PropTypes.bool.isRequired,
    handleCancel: PropTypes.func.isRequired,
    handleSuccess: PropTypes.func.isRequired,
    message: PropTypes.oneOfType([
        PropTypes.string.isRequired,
        PropTypes.object.isRequired,
        PropTypes.func.isRequired,
    ]),
};

export default withTranslation()(ConfirmModal);
