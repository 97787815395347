import { HiOutlinePlus, HiOutlineFilter } from 'react-icons/hi';
import { withTranslation } from 'react-i18next';

const PageHeader = ({ title, handleAdd, handleFilter, t }) => {
    return (
        <div
            style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'flex-end',
                margin: '20px 0',
            }}
        >
            <h1 style={{ marginBottom: 0 }}>{title}</h1>

            <div className="actions">
                {handleFilter && (
                    <button
                        className="btn btn-primary btn-sm"
                        onClick={handleFilter}
                    >
                        <span className="d-none d-sm-block">
                            {t('PAGE_HEADER_FILTERS_BUTTON')}
                        </span>

                        <span
                            className="d-block d-sm-none"
                            style={{ color: 'white' }}
                        >
                            <HiOutlineFilter size={30} />
                        </span>
                    </button>
                )}

                {handleAdd && (
                    <button
                        className="btn btn-primary btn-sm ml-1"
                        onClick={handleAdd}
                    >
                        <span className="d-none d-sm-block">
                            {t('PAGE_HEADER_ADD_BUTTON')}
                        </span>

                        <span
                            className="d-block d-sm-none"
                            style={{ color: 'white' }}
                        >
                            <HiOutlinePlus size={30} />
                        </span>
                    </button>
                )}
            </div>
        </div>
    );
};

export default withTranslation()(PageHeader);
