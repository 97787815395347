import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    withRouter,
} from 'react-router-dom';
import { Provider, connect } from 'react-redux';
import 'semantic-ui-css/semantic.min.css';

import './i18nextConf';
import './index.scss';
import reportWebVitals from './reportWebVitals';
import * as ROUTES from './constants/routes';
import { auth } from './helpers/firebase';
import { Spinner } from './components/Spinner';
import App from './containers/App/App';
import { Login } from './containers/Auth/Login';
import { Register } from './containers/Auth/Register';
import { clearUser, setUser } from './redux/actions';
import { store } from './redux/store';

class Root extends Component {
    componentDidMount() {
        const { history, setUser, clearUser } = this.props;

        auth.onAuthStateChanged(user => {
            if (!user) {
                history.push(ROUTES.LOGIN);
                clearUser();
            } else {
                setUser(user);

                if (
                    history.location.pathname === ROUTES.LOGIN ||
                    history.location.pathname === ROUTES.REGISTER
                ) {
                    history.push(ROUTES.HOME);
                }
            }
        });
    }

    render() {
        const { isLoading } = this.props;

        if (isLoading) {
            return <Spinner />;
        }

        return (
            <Switch>
                <Route path={ROUTES.LOGIN} component={Login} />
                <Route path={ROUTES.REGISTER} component={Register} />
                <Route component={App} />
            </Switch>
        );
    }
}

const mapStateToProps = state => ({
    isLoading: state.user.isLoading,
});

const RootWithAuth = withRouter(
    connect(mapStateToProps, { setUser, clearUser })(Root)
);

ReactDOM.render(
    <Provider store={store}>
        <Router>
            <RootWithAuth />
        </Router>
    </Provider>,
    document.getElementById('root')
);

reportWebVitals();
