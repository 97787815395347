import React from 'react';
import ReCAPTCHA from 'react-google-recaptcha';

const Captcha = ({ handleChange }) => {
    return (
        <div className="mb-2">
            <ReCAPTCHA
                sitekey={process.env.REACT_APP_RECAPTCHA}
                onChange={value =>
                    handleChange({
                        target: {
                            name: 'captcha',
                            value: true,
                        },
                    })
                }
                onExpired={value =>
                    handleChange({
                        target: {
                            name: 'captcha',
                            value: false,
                        },
                    })
                }
                onErrored={value =>
                    handleChange({
                        target: {
                            name: 'captcha',
                            value: false,
                        },
                    })
                }
            />
        </div>
    );
};

export default Captcha;
