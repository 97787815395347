import { NavLink } from 'react-router-dom';
import {
    HiOutlineUserCircle,
    HiOutlineLogout,
    HiChartBar,
    HiTag,
    HiViewList,
    HiOutlineLibrary,
} from 'react-icons/hi';

import './ResponsiveNavbar.scss';
import * as ROUTES from '../constants/routes';

const ResponsiveNavbar = ({ handleLogout, currentLocation }) => {
    const getColor = route => {
        return currentLocation === route ? '#fff' : '#e4e4e4e4';
    };

    return (
        <div className="ResponsiveNavbar-container">
            <NavLink to={ROUTES.ACCOUNTS}>
                <HiOutlineLibrary color={getColor(ROUTES.ACCOUNTS)} size={30} />
            </NavLink>

            <NavLink to={ROUTES.TRANSACTIONS}>
                <HiViewList color={getColor(ROUTES.TRANSACTIONS)} size={30} />
            </NavLink>

            <NavLink to={ROUTES.CATEGORIES}>
                <HiTag color={getColor(ROUTES.CATEGORIES)} size={30} />
            </NavLink>

            <NavLink to={ROUTES.REPORTS}>
                <HiChartBar color={getColor(ROUTES.REPORTS)} size={30} />
            </NavLink>

            <NavLink to={ROUTES.PROFILE}>
                <HiOutlineUserCircle
                    color={getColor(ROUTES.PROFILE)}
                    size={30}
                />
            </NavLink>

            <a href="#!" onClick={handleLogout}>
                <HiOutlineLogout color="#eee" size={30} />
            </a>
        </div>
    );
};

export default ResponsiveNavbar;
