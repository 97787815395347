import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    FormGroup,
    Label,
    Input,
    FormFeedback,
} from 'reactstrap';
import cl from 'classnames';
import MediaQuery from 'react-responsive';
import { withTranslation } from 'react-i18next';

import { firestore } from '../../helpers/firebase';
import {
    validateQuantity,
    validateSelect,
    validateString,
} from '../../helpers/validations';
import { Spinner } from '../../components/Spinner';
import PageHeader from '../../components/PageHeader';
import getAccountTypeName from '../../helpers/getAccountTypeName';
import ConfirmModal from '../../components/ConfirmModal';
import CurrencyInput from '../../components/CurrencyInput';
import CurrencyFormat from '../../components/CurrencyFormat';
import EditButton from '../../components/EditButton';
import DeleteButton from '../../components/DeleteButton';
import { successNotification } from '../../helpers/notifications';

class Accounts extends Component {
    state = {
        openAddAccountModal: false,
        openDeleteAccountModal: false,
        account_id: '',
        name: '',
        value: '',
        type_id: '',
        nameError: '',
        valueError: '',
        type_idError: '',
        isLoading: false,
        accountsRef: firestore.collection('accounts'),
    };

    handleChange = event => {
        event.persist();
        this.setState({
            [event.target.name]: event.target.value,
            [`${event.target.name}Error`]: '',
        });
    };

    toggleAddAccountModal = () =>
        this.setState({
            openAddAccountModal: !this.state.openAddAccountModal,
            name: '',
            value: '',
            type_id: '',
            account_id: '',
            nameError: '',
            valueError: '',
            type_idError: '',
        });

    editAccount = account =>
        this.setState({
            openAddAccountModal: true,
            account_id: account.id,
            name: account.name,
            nameError: '',
            value: account.value,
            type_id: account.type_id,
        });

    handleSubmit = e => {
        e.preventDefault();

        const { t } = this.props;

        const { name, value, type_id } = this.state;

        const nameValid = validateString(t('ACCOUNTS.FORM.NAME_INPUT'), name);
        if (nameValid) {
            this.setState({ nameError: nameValid });
            return;
        }

        const valueValid = validateQuantity(
            t('ACCOUNTS.FORM.INITIAL_VALUE_INPUT'),
            value
        );
        if (valueValid) {
            this.setState({ valueError: valueValid });
            return;
        }

        const typeIdValidation = validateSelect(
            t('ACCOUNTS.FORM.TYPE_INPUT'),
            type_id
        );
        if (typeIdValidation) {
            this.setState({ type_idError: typeIdValidation });
            return;
        }

        if (this.state.account_id === '') {
            return this.createAccount();
        }

        this.updateAccount();
    };

    createAccount = () => {
        const { currentUser, t } = this.props;
        const { name, value, type_id, accountsRef } = this.state;
        const account = {
            user_id: currentUser.uid,
            name,
            value,
            type_id,
        };

        this.setState({ isLoading: true, openAddAccountModal: false });
        accountsRef.add(account).then(response => {
            this.setState(
                {
                    isLoading: false,
                },
                () => {
                    successNotification(
                        t('ACCOUNTS.SUCCESS_MESSAGE.ACCOUNT_CREATED')
                    );
                }
            );
        });
    };

    updateAccount = () => {
        const { currentUser, t } = this.props;
        const { account_id, name, value, type_id, accountsRef } = this.state;
        const account = {
            user_id: currentUser.uid,
            type_id,
            name,
            value,
        };

        this.setState({ isLoading: true, openAddAccountModal: false });

        accountsRef
            .doc(account_id)
            .set(account)
            .then(() => {
                this.setState(
                    {
                        isLoading: false,
                    },
                    () => {
                        successNotification(
                            t('ACCOUNTS.SUCCESS_MESSAGE.ACCOUNT_UPDATED')
                        );
                    }
                );
            });
    };

    confirmDeleteAccount = account =>
        this.setState({
            account_id: account.id,
            name: account.name,
            openDeleteAccountModal: true,
        });

    toggleDeleteAccountModal = () =>
        this.setState({
            account_id: '',
            name: '',
            openDeleteAccountModal: !this.state.openDeleteAccountModal,
        });

    getDeleteMessage = () => {
        const { t } = this.props;

        return (
            <Fragment>
                <p>
                    {t('ACCOUNTS.FORM.CONFIRM_DELETE_MESSAGE_1')}{' '}
                    <strong>{this.state.name}</strong>?
                </p>
                <p>{t('ACCOUNTS.FORM.CONFIRM_DELETE_MESSAGE_2')}</p>
            </Fragment>
        );
    };

    deleteAccount = () => {
        const { t } = this.props;
        const { accountsRef, account_id } = this.state;

        this.setState({ isLoading: true });
        accountsRef
            .doc(account_id)
            .delete()
            .then(() => {
                this.setState(
                    {
                        isLoading: false,
                        openDeleteAccountModal: false,
                        account_id: '',
                        name: '',
                        type_id: '',
                        value: '',
                    },
                    () => {
                        successNotification(
                            t('ACCOUNTS.SUCCESS_MESSAGE.ACCOUNT_DELETED')
                        );
                    }
                );
            });
    };

    render() {
        const { accounts, accountTypes, t } = this.props;

        const {
            openAddAccountModal,
            openDeleteAccountModal,
            account_id,
            type_id,
            name,
            value,
            isLoading,
            nameError,
            valueError,
            type_idError,
        } = this.state;

        return (
            <Fragment>
                {isLoading && <Spinner message="Cargando" />}

                <PageHeader
                    title={t('ACCOUNTS.TITLE')}
                    handleAdd={this.toggleAddAccountModal}
                />

                <MediaQuery minDeviceWidth={769}>
                    <table className="table table-bordered">
                        <thead>
                            <tr>
                                <th width="30%">{t('ACCOUNTS.TABLE.NAME')}</th>
                                <th>{t('ACCOUNTS.TABLE.BALANCE')}</th>
                                <th>{t('ACCOUNTS.TABLE.TYPE')}</th>
                                <th>{t('ACCOUNTS.TABLE.ACTIONS')}</th>
                            </tr>
                        </thead>

                        <tbody>
                            {accounts.length === 0 && (
                                <tr>
                                    <td colSpan={4} className="text-center">
                                        {t('ACCOUNTS.TABLE.EMPTY_STATE')}
                                    </td>
                                </tr>
                            )}

                            {accounts.map(account => (
                                <tr
                                    key={account.id}
                                    className={cl({
                                        'bg-secondary text-white':
                                            account.value < 1,
                                    })}
                                >
                                    <td>{account.name}</td>
                                    <td className="text-left">
                                        <CurrencyFormat value={account.value} />
                                    </td>
                                    <td>
                                        {getAccountTypeName(
                                            accountTypes,
                                            account.type_id
                                        )}
                                    </td>
                                    <td>
                                        <div
                                            className={cl(
                                                {
                                                    'shadow-sm':
                                                        account.value < 1,
                                                },
                                                'btn-group'
                                            )}
                                        >
                                            <EditButton
                                                action={() =>
                                                    this.editAccount(account)
                                                }
                                            />
                                            <DeleteButton
                                                action={() =>
                                                    this.confirmDeleteAccount(
                                                        account
                                                    )
                                                }
                                            />
                                        </div>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </MediaQuery>

                <MediaQuery maxDeviceWidth={768}>
                    {accounts.length === 0 && (
                        <div className="account-item">
                            <p>{t('ACCOUNTS.TABLE.EMPTY_STATE')}</p>
                        </div>
                    )}

                    {accounts.map(account => (
                        <div
                            key={account.id}
                            className={cl(
                                {
                                    'bg-secondary text-white':
                                        account.value < 1,
                                },
                                'account-item'
                            )}
                        >
                            <p className="name">{account.name}</p>
                            <p className="description">
                                {getAccountTypeName(
                                    accountTypes,
                                    account.type_id
                                )}
                            </p>
                            <p className="value">
                                <CurrencyFormat value={account.value} />
                            </p>
                            <div>
                                <div
                                    className={cl(
                                        { 'shadow-sm': account.value < 1 },
                                        'btn-group'
                                    )}
                                >
                                    <EditButton
                                        action={() => this.editAccount(account)}
                                    />
                                    <DeleteButton
                                        action={() =>
                                            this.confirmDeleteAccount(account)
                                        }
                                    />
                                </div>
                            </div>
                        </div>
                    ))}
                    <div style={{ marginTop: '4rem' }} />
                </MediaQuery>

                <Modal isOpen={openAddAccountModal}>
                    <form onSubmit={this.handleSubmit}>
                        <ModalHeader>
                            {account_id === ''
                                ? t('ACCOUNTS.FORM.ADD_TITLE')
                                : t('ACCOUNTS.FORM.EDIT_TITLE')}
                        </ModalHeader>

                        <ModalBody>
                            <FormGroup>
                                <Label for="name">
                                    {t('ACCOUNTS.FORM.NAME_INPUT')}
                                </Label>
                                <Input
                                    className="form-control"
                                    placeholder={t(
                                        'ACCOUNTS.FORM.NAME_PLACEHOLDER'
                                    )}
                                    name="name"
                                    value={name}
                                    onChange={this.handleChange}
                                    invalid={!!nameError}
                                />
                                {!!nameError && (
                                    <FormFeedback>{nameError}</FormFeedback>
                                )}
                            </FormGroup>

                            <FormGroup>
                                <Label for="value">
                                    {t('ACCOUNTS.FORM.INITIAL_VALUE_INPUT')}
                                </Label>
                                <CurrencyInput
                                    name="value"
                                    id="value"
                                    classes={cl(
                                        { 'is-invalid': !!valueError },
                                        'form-control'
                                    )}
                                    value={value}
                                    onChange={this.handleChange}
                                />
                                {!!valueError && (
                                    <FormFeedback>{valueError}</FormFeedback>
                                )}
                            </FormGroup>

                            <FormGroup>
                                <Label for="type_id">
                                    {t('ACCOUNTS.FORM.TYPE_INPUT')}
                                </Label>
                                <Input
                                    type="select"
                                    className="form-control"
                                    name="type_id"
                                    id="type_id"
                                    value={type_id}
                                    onChange={this.handleChange}
                                    invalid={!!type_idError}
                                >
                                    <option value="" />
                                    {accountTypes.map(account => (
                                        <option
                                            key={account.id}
                                            value={account.id}
                                        >
                                            {account.name}
                                        </option>
                                    ))}
                                </Input>
                                {!!type_idError && (
                                    <FormFeedback>{type_idError}</FormFeedback>
                                )}
                            </FormGroup>
                        </ModalBody>

                        <ModalFooter>
                            <button
                                className="btn btn-dark"
                                onClick={this.toggleAddAccountModal}
                            >
                                {t('ACCOUNTS.FORM.CANCEL_BUTTON')}
                            </button>

                            <button className="btn btn-primary" type="submit">
                                {t('ACCOUNTS.FORM.SUBMIT_BUTTON')}
                            </button>
                        </ModalFooter>
                    </form>
                </Modal>

                <ConfirmModal
                    open={openDeleteAccountModal}
                    handleCancel={this.toggleDeleteAccountModal}
                    handleSuccess={this.deleteAccount}
                    message={() => this.getDeleteMessage()}
                />
            </Fragment>
        );
    }
}

const mapStateToProps = state => ({
    currentUser: state.user.currentUser,
    accountTypes: state.catalogs.accountTypes,
    accounts: state.catalogs.accounts,
});

export default withTranslation()(
    withRouter(connect(mapStateToProps)(Accounts))
);
