import isFinite from 'lodash/isFinite';

export const validateString = (fieldName, fieldValue, minLength = 3) => {
    if (fieldValue.trim() === '') {
        return `${fieldName} es requerido`;
    }

    // if (/[^a-zA-Z-0-9-]/.test(fieldValue)) {
    //     return 'Invalid characters';
    // }

    if (fieldValue.trim().length < minLength) {
        return `${fieldName} debe tener al menos ${minLength} caracteres`;
    }

    return null;
};

export const validateQuantity = (fieldName, fieldValue) => {
    if (!isFinite(fieldValue)) {
        return `${fieldName} es requerido`;
    }

    return null;
};

export const validateSelect = (fieldName, fieldValue) => {
    if (fieldValue === '') {
        return `${fieldName} es requerido`;
    }

    return null;
};

export const validateEmail = (fieldName, fieldValue) => {
    if (fieldValue.trim() === '') {
        return `${fieldName} es requerido`;
    }

    if (
        !/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
            fieldValue
        )
    ) {
        return `${fieldName} invalido`;
    }

    return null;
};

export const validatePassword = (fieldName, fieldValue) => {
    if (fieldValue.trim() === '') {
        return `${fieldName} is required`;
    }

    if (/[^a-z0-9]/gi.test(fieldValue)) {
        return `${fieldName} is invalid`;
    }
};
