import React from 'react';
import CurrencyFormat from './CurrencyFormat';

import './ReportAmountBox.scss';

const ReportAmountBox = ({
    title,
    containerClasses,
    amountClasses,
    amount,
}) => {
    return (
        <div className={`ReportAmountBox ${containerClasses}`}>
            <p className={`ReportAmountBox__title`}>{title}</p>
            <p className={`ReportAmountBox__amount ${amountClasses}`}>
                <CurrencyFormat value={amount} />
            </p>
        </div>
    );
};

export default ReportAmountBox;
