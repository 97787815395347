import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import {
    Container,
    Row,
    Col,
    Card,
    Button,
    FormGroup,
    Input,
    FormFeedback,
} from 'reactstrap';
import { GiTakeMyMoney } from 'react-icons/gi';

import { REGISTER } from '../../constants/routes';
import firebase from '../../helpers/firebase';
import { validateEmail, validatePassword } from '../../helpers/validations';
import Captcha from '../../components/Captcha';

class Login extends Component {
    state = {
        email: '',
        password: '',
        emailError: '',
        passwordError: '',
        errors: [],
        loading: false,
        captcha: false,
    };

    handleChange = event => {
        this.setState({
            [event.target.name]: event.target.value,
            [`${event.target.name}Error`]: '',
        });
    };

    handleSubmit = event => {
        event.preventDefault();

        const { email, password, captcha } = this.state;

        const emailValid = validateEmail('Email', email);
        if (emailValid) {
            this.setState({ emailError: emailValid });
            return;
        }

        const passwordValid = validatePassword('Contraseña', password);
        if (passwordValid) {
            this.setState({ passwordError: passwordValid });
            return;
        }

        if (!captcha) {
            return;
        }

        this.setState({ loading: true });
        firebase
            .auth()
            .signInWithEmailAndPassword(email, password)
            .then(signedInUser => {
                this.setState({ loading: false });
            })
            .catch(err => {
                if (err.code === 'auth/wrong-password') {
                    this.setState({
                        passwordError: 'Contraseña errada',
                        loading: false,
                    });

                    return;
                }

                let error;

                switch (err.code) {
                    case 'auth/invalid-email':
                        error = 'Email invalido';
                        break;

                    case 'auth/user-disabled':
                        error = 'Tu usuario ha sido deshabilitado';
                        break;

                    case 'auth/user-not-found':
                        error = 'El email no esta registrado';
                        break;

                    default:
                        error = 'Ha ocurrido un error';
                }

                this.setState({
                    emailError: error,
                    loading: false,
                });
            });
    };

    render() {
        const { email, password, emailError, passwordError, loading } =
            this.state;

        return (
            <Container className="h-100">
                <Row className="h-100 justify-content-center align-content-center">
                    <Col xs={12} style={{ maxWidth: 450 }}>
                        <h2 className="text-primary text-center d-flex flex-column align-items-center">
                            <GiTakeMyMoney size={'4rem'} />
                            <span className="font-weight-bold">
                                Entrar a Expenses
                            </span>
                        </h2>

                        <form
                            onSubmit={this.handleSubmit}
                            noValidate={true}
                            className="my-2"
                        >
                            <Card body>
                                <FormGroup>
                                    <Input
                                        type="email"
                                        name="email"
                                        value={email}
                                        placeholder="Email"
                                        onChange={this.handleChange}
                                        invalid={!!emailError}
                                    />
                                    {emailError && (
                                        <FormFeedback>
                                            {emailError}
                                        </FormFeedback>
                                    )}
                                </FormGroup>

                                <FormGroup>
                                    <Input
                                        type="password"
                                        name="password"
                                        value={password}
                                        placeholder="Contraseña"
                                        onChange={this.handleChange}
                                        invalid={!!passwordError}
                                    />
                                    {passwordError && (
                                        <FormFeedback>
                                            {passwordError}
                                        </FormFeedback>
                                    )}
                                </FormGroup>

                                <Captcha handleChange={this.handleChange} />

                                <Button disabled={loading} color="primary">
                                    Enviar
                                </Button>
                            </Card>
                        </form>

                        <Card body>
                            <p className="text-center">
                                ¿Aún no tienes cuenta?{' '}
                                <Link to={REGISTER}>Registrate</Link>
                            </p>
                        </Card>
                    </Col>
                </Row>
            </Container>
        );
    }
}

export { Login };
