import PropTypes from 'prop-types';
import { HiOutlineTrash } from 'react-icons/hi';
import { withTranslation } from 'react-i18next';

const DeleteButton = ({ action, t }) => {
    return (
        <button className="btn btn-danger btn-sm" onClick={action}>
            <span className="d-none d-sm-block">{t('DELETE_BUTTON')}</span>

            <span className="d-block d-sm-none">
                <HiOutlineTrash size={18} />
            </span>
        </button>
    );
};

DeleteButton.propTypes = {
    action: PropTypes.func.isRequired,
};

export default withTranslation()(DeleteButton);
