import PropTypes from 'prop-types';
import NumberFormat from 'react-number-format';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';

const CurrencyFormat = props => {
    const {
        value,
        decimals,
        classes,
        thousandSeparator,
        decimalSeparator,
        displayType,
        suffix,
        prefix,
    } = props;

    const { i18n } = useTranslation();

    return (
        <NumberFormat
            value={value}
            displayType={displayType}
            thousandSeparator={thousandSeparator}
            decimalSeparator={decimalSeparator}
            decimalScale={decimals}
            className={cx('currency-input', classes)}
            suffix={i18n.language === 'en' ? suffix : null}
            prefix={i18n.language === 'es' ? prefix : null}
        />
    );
};

CurrencyFormat.defaultProps = {
    classes: '',
    decimals: 2,
    thousandSeparator: '.',
    decimalSeparator: ',',
    displayType: 'text',
    suffix: ' $',
    prefix: '$ ',
};

CurrencyFormat.propTypes = {
    value: PropTypes.number.isRequired,
};

export default CurrencyFormat;
