import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import {
    Row,
    Col,
    TabContent,
    TabPane,
    Nav,
    NavItem,
    NavLink,
    Input,
    FormGroup,
} from 'reactstrap';
import classnames from 'classnames';
import { connect } from 'react-redux';
import DatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import es from 'date-fns/locale/es';
import moment from 'moment';
import { withTranslation } from 'react-i18next';
import map from 'lodash/map';

import { firestore, fromMillis } from '../../helpers/firebase';
import { EXPENSE, INCOME } from '../../constants/transaction_types';
import { Spinner } from '../../components/Spinner';
import PageHeader from '../../components/PageHeader';
import BarChart from '../../components/BarChart';
import ReportAmountBox from '../../components/ReportAmountBox';

registerLocale('es', es);

class Reports extends Component {
    state = {
        transactionsRef: firestore.collection('transactions'),
        isLoading: false,
        showFilters: false,
        activeTab: '1',
        filterCategoryId: '',
        filterAccountId: '',
        filterTransactionType: '',
        transactions: [],
        starDate: moment(new Date()).subtract(7, 'days').toDate(),
        starDateError: '',
        endDate: new Date(),
        endDateError: '',
    };

    componentDidMount() {
        const { currentUser } = this.props;
        const { transactionsRef, starDate, endDate } = this.state;
        starDate.setHours(0, 0, 0);
        endDate.setHours(23, 59, 59);

        this.setState({ isLoading: true });
        transactionsRef
            .where('user_id', '==', currentUser.uid)
            .where('date', '>', fromMillis(starDate))
            .where('date', '<', fromMillis(endDate))
            .orderBy('date', 'desc')
            .get()
            .then(querySnapshot => {
                const transactions = [];
                querySnapshot.forEach(doc => {
                    transactions.push({
                        id: doc.id,
                        ...doc.data(),
                        value: parseFloat(doc.data().value),
                    });
                });

                this.setState({
                    transactions,
                    isLoading: false,
                });
            });
    }

    handleChange = event => {
        const { value, transactionType } = this.state;

        const newState = {
            [event.target.name]: event.target.value,
            [`${event.target.name}Error`]: '',
        };

        if (
            event.target.name === 'transactionType' &&
            event.target.value !== transactionType
        ) {
            newState.value =
                event.target.value === INCOME ? Math.abs(value) : value * -1;
        }

        this.setState(newState);
    };

    toggle = tab => {
        if (this.state.activeTab !== tab) {
            this.setState({ activeTab: tab });
        }
    };

    toggleShowFilters = () => {
        return this.setState({ showFilters: !this.state.showFilters });
    };

    setStarDate = date => {
        const { endDate } = this.state;

        if (
            new Date(date[0].toDateString()) > new Date(endDate.toDateString())
        ) {
            return this.setState({
                starDateError: 'Esta fecha no puede ser mayor a la fecha final',
            });
        }

        this.setState({ starDate: date[0], starDateError: '' });
    };

    setEndDate = date => {
        const { starDate } = this.state;

        if (
            new Date(date[0].toDateString()) < new Date(starDate.toDateString())
        ) {
            return this.setState({
                endDateError:
                    'Esta fecha no puede ser menor a la fecha de principio  ',
            });
        }

        this.setState({ endDate: date[0], endDateError: '' });
    };

    loadTransactions = () => {
        const { currentUser } = this.props;
        const { transactionsRef, filterAccountId, starDate, endDate } =
            this.state;

        this.setState({ isLoading: true, showFilters: false });
        let query = transactionsRef
            .where('user_id', '==', currentUser.uid)
            .where('date', '>', fromMillis(starDate))
            .where('date', '<', fromMillis(endDate));

        if (filterAccountId) {
            query = query.where('account_id', '==', filterAccountId);
        }

        query = query.orderBy('date', 'desc');

        query.get().then(querySnapshot => {
            const newTransactions = [];
            querySnapshot.forEach(doc => {
                newTransactions.push({
                    id: doc.id,
                    ...doc.data(),
                    value: parseFloat(doc.data().value),
                });
            });

            this.setState({
                transactions: newTransactions,
                isLoading: false,
            });
        });
    };

    render() {
        const { accounts, categories, t } = this.props;

        const {
            isLoading,
            showFilters,
            filterAccountId,
            activeTab,
            transactions,
            starDate,
            starDateError,
            endDate,
            endDateError,
        } = this.state;

        const dataSet = {};

        categories.forEach(category => {
            dataSet[category.id] = {
                id: category.id,
                name: category.name,
                transactionType: category.transactionType,
                value: 0,
            };
        });

        if (Object.keys(dataSet).length > 0) {
            for (let index = 0; index < transactions.length; index++) {
                const categoryId = transactions[index].category_id;

                dataSet[categoryId].value =
                    dataSet[categoryId].value +
                    Math.abs(transactions[index].value);
            }
        }

        const chartExpensesLabels = [];
        const chartExpensesData = [];
        const chartIncomeLabels = [];
        const chartIncomeData = [];
        map(dataSet, function (item) {
            if (item.transactionType === EXPENSE) {
                chartExpensesLabels.push(item.name);
                chartExpensesData.push(item.value);
            } else {
                chartIncomeLabels.push(item.name);
                chartIncomeData.push(item.value);
            }
        });

        const totalExpenses = chartExpensesData.reduce((total, item) => {
            return (total = total + item);
        }, 0);

        const totalIncomes = chartIncomeData.reduce((total, item) => {
            return (total = total + item);
        }, 0);

        return (
            <Fragment>
                {isLoading && <Spinner />}

                <Row>
                    <Col xs={12}>
                        <PageHeader
                            title={t('REPORTS.TITLE')}
                            handleFilter={this.toggleShowFilters}
                        />

                        {showFilters && (
                            <div className="filters card mb-2">
                                <div className="card-body">
                                    <Row>
                                        <Col xs={12}>
                                            {/* accounts */}
                                            <FormGroup>
                                                <Input
                                                    type="select"
                                                    className="form-control"
                                                    name="filterAccountId"
                                                    id="filterAccountId"
                                                    value={filterAccountId}
                                                    onChange={this.handleChange}
                                                >
                                                    <option value="">
                                                        {t(
                                                            'REPORTS.FILTERS.ALL_ACCOUNT_OPTION'
                                                        )}
                                                    </option>
                                                    {accounts.map(account => (
                                                        <option
                                                            key={account.id}
                                                            value={account.id}
                                                        >
                                                            {account.name}
                                                        </option>
                                                    ))}
                                                </Input>
                                            </FormGroup>
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col xs={12} sm={6}>
                                            {t('REPORTS.FILTERS.START_DATE')}:
                                            <FormGroup>
                                                <DatePicker
                                                    id="date"
                                                    className={classnames(
                                                        {
                                                            'is-invalid':
                                                                !!starDateError,
                                                        },
                                                        'form-control'
                                                    )}
                                                    locale={es}
                                                    selected={starDate}
                                                    onChange={this.setStarDate}
                                                    dateFormat="dd/MM/yyyy"
                                                    selectsRange
                                                />

                                                {!!starDateError && (
                                                    <div
                                                        className="invalid-feedback"
                                                        style={{
                                                            display: 'block',
                                                        }}
                                                    >
                                                        {starDateError}
                                                    </div>
                                                )}
                                            </FormGroup>
                                        </Col>

                                        <Col xs={12} sm={6}>
                                            {t('REPORTS.FILTERS.END_DATE')}:
                                            <FormGroup>
                                                <DatePicker
                                                    id="date"
                                                    className={classnames(
                                                        {
                                                            'is-invalid':
                                                                !!endDateError,
                                                        },
                                                        'form-control'
                                                    )}
                                                    locale={es}
                                                    selected={endDate}
                                                    onChange={this.setEndDate}
                                                    dateFormat="dd/MM/yyyy"
                                                    selectsRange
                                                />

                                                {!!endDateError && (
                                                    <div
                                                        className="invalid-feedback"
                                                        style={{
                                                            display: 'block',
                                                        }}
                                                    >
                                                        {endDateError}
                                                    </div>
                                                )}
                                            </FormGroup>
                                        </Col>

                                        <Col
                                            xs={12}
                                            sm={6}
                                            className="text-right"
                                        >
                                            <button
                                                className="btn btn-primary"
                                                onClick={() =>
                                                    this.loadTransactions(false)
                                                }
                                            >
                                                {t(
                                                    'REPORTS.FILTERS.SUBMIT_BUTTON'
                                                )}
                                            </button>
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        )}

                        <Nav tabs justified className="reports-tabs">
                            <NavItem>
                                <NavLink
                                    className={classnames({
                                        active: activeTab === '1',
                                    })}
                                    onClick={() => {
                                        this.toggle('1');
                                    }}
                                >
                                    Resumen
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink
                                    className={classnames({
                                        active: activeTab === '2',
                                    })}
                                    onClick={() => {
                                        this.toggle('2');
                                    }}
                                >
                                    {t('REPORTS.NAV.EXPENSE')}
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink
                                    className={classnames({
                                        active: activeTab === '3',
                                    })}
                                    onClick={() => {
                                        this.toggle('3');
                                    }}
                                >
                                    {t('REPORTS.NAV.INCOME')}
                                </NavLink>
                            </NavItem>
                        </Nav>

                        <TabContent activeTab={activeTab}>
                            <TabPane tabId="1">
                                <div className="report-resume__container">
                                    <ReportAmountBox
                                        title="Ingresos"
                                        amountClasses="text-success"
                                        amount={totalIncomes}
                                    />

                                    <ReportAmountBox
                                        title="Gastos"
                                        amountClasses="text-danger"
                                        amount={totalExpenses}
                                    />

                                    <ReportAmountBox
                                        title="Diferencia"
                                        amountClasses="text-info"
                                        amount={totalIncomes - totalExpenses}
                                    />
                                </div>

                                <div style={{ marginTop: '4rem' }} />
                            </TabPane>

                            <TabPane tabId="2">
                                {transactions.length === 0 && (
                                    <p className="text-center">
                                        {t('REPORTS.GRAPH.EMPTY_STATE')}
                                    </p>
                                )}

                                {transactions.length > 0 && (
                                    <BarChart
                                        labels={chartExpensesLabels}
                                        dataSet={chartExpensesData}
                                    />
                                )}

                                <div style={{ marginTop: '4rem' }} />
                            </TabPane>

                            <TabPane tabId="3">
                                {transactions.length === 0 && (
                                    <p className="text-center">
                                        {t('REPORTS.GRAPH.EMPTY_STATE')}
                                    </p>
                                )}

                                {transactions.length > 0 && (
                                    <BarChart
                                        labels={chartIncomeLabels}
                                        dataSet={chartIncomeData}
                                    />
                                )}
                                <div style={{ marginTop: '4rem' }} />
                            </TabPane>
                        </TabContent>
                    </Col>
                </Row>
            </Fragment>
        );
    }
}

const mapStateToProps = state => ({
    currentUser: state.user.currentUser,
    accounts: state.catalogs.accounts,
    categories: state.catalogs.categories,
});

export default withTranslation()(withRouter(connect(mapStateToProps)(Reports)));
