import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

const EN = 'en';
const ES = 'es';
const EXPENSES_LANG = 'EXPENSES_LANG';

const LangSelector = () => {
    let language = localStorage.getItem(EXPENSES_LANG);
    if (!language) {
        language = EN;
    }

    const { i18n } = useTranslation();
    const [selectedLang, setSelectedLang] = useState(language);

    const changeLanguage = value => {
        setSelectedLang(value);
        i18n.changeLanguage(value);
        localStorage.setItem(EXPENSES_LANG, value);
    };

    useEffect(() => {
        i18n.changeLanguage(selectedLang);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <button
            onClick={() => changeLanguage(selectedLang === ES ? EN : ES)}
            className="mr-4 bg-primary text-white px-2 font-weight-bold cursor-pointer border-0 rounded"
        >
            {selectedLang === ES ? 'En' : 'Es'}
        </button>
    );
};

export default LangSelector;
