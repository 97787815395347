import * as actionTypes from './types';
import { firestore } from '../../helpers/firebase';
import { LOAD_SUCCESS, LOADING } from '../../constants/loadStatus';

export const loadCatalogs = userId => dispatch => {
    dispatch(setLoadingStatus(LOADING));

    Promise.all([
        firestore.collection('categories').where('user_id', '==', userId).get(),
        //firestore.collection('accounts').where('user_id', '==', userId).get(),
        firestore.collection('account_types').get(),
    ]).then(async querySnapshots => {
        const categories = [];
        querySnapshots[0].forEach(doc => {
            categories.push({
                id: doc.id,
                ...doc.data(),
            });
        });

        // const accounts = [];
        // querySnapshots[1].forEach(doc => {
        //     accounts.push({
        //         id: doc.id,
        //         ...doc.data(),
        //         value: parseFloat(doc.data().value),
        //     });
        // });

        const accountTypes = [];
        querySnapshots[1].forEach(doc => {
            accountTypes.push({
                id: doc.id,
                ...doc.data(),
            });
        });

        dispatch(setCatalogs(accountTypes, categories));
        dispatch(setLoadingStatus(LOAD_SUCCESS));
    });
};

export const setLoadingStatus = loadingStatus => {
    return {
        type: actionTypes.SET_LOADING_STATUS,
        payload: { loadingStatus },
    };
};

export const setAccountTypes = accountTypes => {
    return {
        type: actionTypes.SET_ACCOUNT_TYPES,
        payload: { accountTypes },
    };
};

export const setCategories = categories => {
    return {
        type: actionTypes.SET_CATEGORIES,
        payload: { categories },
    };
};

export const updateCategory = (categoryId, category) => {
    return {
        type: actionTypes.UPDATE_CATEGORY,
        payload: { categoryId, category },
    };
};

export const removeCategory = categoryId => {
    return {
        type: actionTypes.REMOVE_CATEGORY,
        payload: { categoryId },
    };
};

export const addCategory = category => {
    return {
        type: actionTypes.ADD_CATEGORY,
        payload: { category },
    };
};

export const setAccounts = accounts => {
    return {
        type: actionTypes.SET_ACCOUNTS,
        payload: { accounts },
    };
};

export const updateAccount = (accountId, account) => {
    return {
        type: actionTypes.UPDATE_ACCOUNT,
        payload: { accountId, account },
    };
};

export const removeAccount = accountId => {
    return {
        type: actionTypes.REMOVE_ACCOUNT,
        payload: { accountId },
    };
};

export const addAccount = account => {
    return {
        type: actionTypes.ADD_ACCOUNT,
        payload: { account },
    };
};

export const setCatalogs = (accountTypes, categories) => {
    return {
        type: actionTypes.SET_CATALOGS,
        payload: { accountTypes, categories },
    };
};
