import {combineReducers} from 'redux';
import * as actionTypes from './types';
import catalogs from './catalogs/reducer';

const initialUserState = {
    currentUser: null,
    isLoading: true,
};

const userReducer = (state = initialUserState, action) => {
    switch (action.type) {
        case actionTypes.SET_USER:
            return {
                ...state,
                currentUser: action.payload.currentUser,
                isLoading: false,
            };

        case actionTypes.CLEAR_USER:
            return {
                ...state,
                isLoading: false,
            };

        default:
            return state;
    }
};

const rootReducer = combineReducers({
    user: userReducer,
    catalogs,
});

export default rootReducer;
