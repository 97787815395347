import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import {
    Container,
    Row,
    Col,
    Card,
    Button,
    FormGroup,
    Input,
    FormFeedback,
} from 'reactstrap';
import md5 from 'md5';
import { GiTakeMyMoney } from 'react-icons/gi';

import { LOGIN } from '../../constants/routes';
import { auth, firestore } from '../../helpers/firebase';
import {
    validateEmail,
    validatePassword,
    validateString,
} from '../../helpers/validations';
import Captcha from '../../components/Captcha';

class Register extends Component {
    state = {
        fullName: '',
        fullNameError: '',
        email: '',
        emailError: '',
        password: '',
        passwordError: '',
        passwordConfirmation: '',
        passwordConfirmationError: '',
        errors: [],
        loading: false,
        usersRef: firestore.collection('users'),
        captcha: false,
    };

    handleChange = event => {
        this.setState({
            [event.target.name]: event.target.value,
            [`${event.target.name}Error`]: '',
        });
    };

    handleSubmit = event => {
        event.preventDefault();

        const { fullName, email, password, passwordConfirmation, captcha } =
            this.state;

        const fullNameValid = validateString('Nombre', fullName);
        if (fullNameValid) {
            this.setState({ fullNameError: fullNameValid });
            return;
        }

        const emailValid = validateEmail('Email', email);
        if (emailValid) {
            this.setState({ emailError: emailValid });
            return;
        }

        const passwordValid = validatePassword('Contraseña', password);
        if (passwordValid) {
            this.setState({ passwordError: passwordValid });
            return;
        }

        if (password !== passwordConfirmation) {
            this.setState({
                passwordConfirmationError: 'Las contraseñas no coinciden',
            });
            return;
        }

        if (!captcha) {
            return;
        }

        this.setState({ loading: true });
        auth.createUserWithEmailAndPassword(
            this.state.email,
            this.state.password
        )
            .then(createdUser => {
                createdUser.user
                    .updateProfile({
                        displayName: this.state.fullName,
                        photoURL: `http://gravatar.com/avatar/${md5(
                            createdUser.user.email
                        )}?d=identicon`,
                    })
                    .then(() => {
                        this.saveUser(createdUser).then(() => {
                            this.setState({ loading: false });
                        });
                    })
                    .catch(err => {
                        this.setState({
                            emailError: 'Ocurrio un error',
                            loading: false,
                        });
                    });
            })
            .catch(err => {
                if (err.code === 'auth/weak-password') {
                    this.setState({
                        passwordError:
                            'La contraseña es muy debil, escoge otra',
                        loading: false,
                    });

                    return;
                }

                let error = '';
                if (err.code === 'auth/invalid-email') {
                    error = 'El email es invalido';
                }

                if (err.code === 'auth/email-already-in-use') {
                    error = 'El email ya se encuentra registrado';
                }

                this.setState({
                    emailError: error,
                    loading: false,
                });
            });
    };

    saveUser = createdUser => {
        return this.state.usersRef.doc(createdUser.user.uid).set({
            name: createdUser.user.displayName,
            email: createdUser.user.email,
            avatar: createdUser.user.photoURL,
        });
    };

    render() {
        const {
            fullName,
            email,
            password,
            passwordConfirmation,
            fullNameError,
            emailError,
            passwordError,
            passwordConfirmationError,
            loading,
        } = this.state;

        return (
            <Container className="h-100">
                <Row className="h-100 justify-content-center align-content-center">
                    <Col xs={12} style={{ maxWidth: 450 }}>
                        <h2 className="text-primary text-center d-flex flex-column align-items-center">
                            <GiTakeMyMoney size={'4rem'} />
                            <span className="font-weight-bold">
                                Comienza a tomar el control de tus finanzas
                            </span>
                        </h2>

                        <form
                            onSubmit={this.handleSubmit}
                            noValidate={true}
                            className="my-2"
                        >
                            <Card body>
                                <FormGroup>
                                    <Input
                                        type="text"
                                        name="fullName"
                                        placeholder="Nombre"
                                        value={fullName}
                                        onChange={this.handleChange}
                                        invalid={!!fullNameError}
                                    />
                                    {fullNameError && (
                                        <FormFeedback>
                                            {fullNameError}
                                        </FormFeedback>
                                    )}
                                </FormGroup>

                                <FormGroup>
                                    <Input
                                        type="email"
                                        name="email"
                                        placeholder="Email"
                                        value={email}
                                        onChange={this.handleChange}
                                        invalid={!!emailError}
                                    />
                                    {emailError && (
                                        <FormFeedback>
                                            {emailError}
                                        </FormFeedback>
                                    )}
                                </FormGroup>

                                <FormGroup>
                                    <Input
                                        type="password"
                                        name="password"
                                        placeholder="Contraseña"
                                        value={password}
                                        onChange={this.handleChange}
                                        invalid={!!passwordError}
                                    />
                                    {passwordError && (
                                        <FormFeedback>
                                            {passwordError}
                                        </FormFeedback>
                                    )}
                                </FormGroup>

                                <FormGroup>
                                    <Input
                                        type="password"
                                        name="passwordConfirmation"
                                        placeholder="Confirmar contraseña"
                                        value={passwordConfirmation}
                                        onChange={this.handleChange}
                                        invalid={!!passwordConfirmationError}
                                    />
                                    {passwordConfirmationError && (
                                        <FormFeedback>
                                            {passwordConfirmationError}
                                        </FormFeedback>
                                    )}
                                </FormGroup>

                                <Captcha handleChange={this.handleChange} />

                                <Button disabled={loading} color="primary">
                                    Registro
                                </Button>
                            </Card>
                        </form>

                        <Card body>
                            <p className="text-center">
                                ¿Ya eres usuario?{' '}
                                <Link to={LOGIN} className="d-inline">
                                    Accede
                                </Link>
                            </p>
                        </Card>
                    </Col>
                </Row>
            </Container>
        );
    }
}

export { Register };
