import React from 'react';
import PropTypes from 'prop-types';
import { ButtonGroup } from 'reactstrap';
import { withTranslation } from 'react-i18next';

import { INCOME, EXPENSE } from '../constants/transaction_types';

const TransactionTypesButtons = ({ fieldName, typeActive, onChange, t }) => {
    return (
        <ButtonGroup className="btn-block">
            <button
                type="button"
                className={`btn ${
                    typeActive === EXPENSE ? 'btn-danger' : 'btn-outline-danger'
                }`}
                name={fieldName}
                value={EXPENSE}
                onClick={onChange}
            >
                {t('TRANSACTIONS_TYPES_BUTTONS.EXPENSE_OPTION')}
            </button>
            <button
                type="button"
                className={`btn ${
                    typeActive === INCOME
                        ? 'btn-success'
                        : 'btn-outline-success'
                }`}
                name={fieldName}
                value={INCOME}
                onClick={onChange}
            >
                {t('TRANSACTIONS_TYPES_BUTTONS.INCOME_OPTION')}
            </button>
        </ButtonGroup>
    );
};

TransactionTypesButtons.propTypes = {
    typeActive: PropTypes.oneOf([EXPENSE, INCOME]),
    onChange: PropTypes.func.isRequired,
};

TransactionTypesButtons.defaultProps = {
    fieldName: 'transactionType',
};

export default withTranslation()(TransactionTypesButtons);
