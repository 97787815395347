import createDateFromFormatted from './createDateFromFormatted';

const displayDate = date => {
    const newDate = createDateFromFormatted(date);
    return `${newDate.getDate()}/${
        newDate.getMonth() === 0 ? 12 : newDate.getMonth()
    }/${newDate.getFullYear()}`;
};

export default displayDate;
