import PropTypes from 'prop-types';
import NumberFormat from 'react-number-format';
import cx from 'classnames';

const CurrencyInput = props => {
    const {
        value,
        decimals,
        classes,
        thousandSeparator,
        decimalSeparator,
        displayType,
        onChange,
        name,
    } = props;

    return (
        <NumberFormat
            type="tel"
            value={value}
            displayType={displayType}
            thousandSeparator={thousandSeparator}
            decimalSeparator={decimalSeparator}
            decimalScale={decimals}
            className={cx('currency-input', classes)}
            onValueChange={e =>
                onChange({
                    target: {
                        name,
                        value: e.floatValue,
                    },
                    persist: () => {},
                })
            }
        />
    );
};

CurrencyInput.defaultProps = {
    classes: '',
    decimals: 2,
    thousandSeparator: '.',
    decimalSeparator: ',',
};

CurrencyInput.propTypes = {
    name: PropTypes.string.isRequired,
    value: PropTypes.any,
    onChange: PropTypes.func.isRequired,
};

export default CurrencyInput;
