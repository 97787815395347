export const SET_CATALOGS = 'SET_CATALOGS';

export const SET_LOADING_STATUS = 'SET_LOADING_STATUS';

export const SET_ACCOUNT_TYPES = 'SET_ACCOUNT_TYPES';

export const SET_ACCOUNTS = 'SET_ACCOUNTS';
export const UPDATE_ACCOUNT = 'UPDATE_ACCOUNT';
export const REMOVE_ACCOUNT = 'REMOVE_ACCOUNT';
export const ADD_ACCOUNT = 'ADD_ACCOUNT';

export const SET_CATEGORIES = 'SET_CATEGORIES';
export const UPDATE_CATEGORY = 'UPDATE_CATEGORY';
export const REMOVE_CATEGORY = 'REMOVE_CATEGORY';
export const ADD_CATEGORY = 'ADD_CATEGORY';
