import * as actionTypes from './types';

const initialState = {
    loadingStatus: '',
    accountTypes: [],
    categories: [],
    accounts: [],
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SET_LOADING_STATUS:
            return {
                ...state,
                loadingStatus: action.payload.loadingStatus,
            };

        case actionTypes.SET_CATALOGS:
            return {
                ...state,
                accountTypes: action.payload.accountTypes,
                categories: action.payload.categories,
                //accounts: action.payload.accounts,
            };

        case actionTypes.SET_CATEGORIES:
            return {
                ...state,
                loadingStatus: action.payload.loadingStatus,
                categories: action.payload.categories,
            };

        case actionTypes.ADD_CATEGORY:
            const newCategories = state.categories;
            newCategories.push(action.payload.category);
            return {
                ...state,
                categories: newCategories,
            };

        case actionTypes.UPDATE_CATEGORY:
            const upCategories = state.categories;
            const catIndex = upCategories.findIndex(
                cat => cat.id === action.payload.categoryId
            );
            upCategories[catIndex] = action.payload.category;
            return {
                ...state,
                categories: upCategories,
            };

        case actionTypes.REMOVE_CATEGORY:
            return {
                ...state,
                categories: state.categories.filter(
                    category => category.id !== action.payload.categoryId
                ),
            };

        case actionTypes.SET_ACCOUNTS:
            return {
                ...state,
                loadingStatus: action.payload.loadingStatus,
                accounts: action.payload.accounts,
            };

        case actionTypes.ADD_ACCOUNT:
            const newAccounts = state.accounts;
            newAccounts.push(action.payload.account);
            return {
                ...state,
                accounts: newAccounts,
            };

        case actionTypes.UPDATE_ACCOUNT:
            const upAccounts = state.accounts;
            const actIndex = upAccounts.findIndex(
                act => act.id === action.payload.accountId
            );
            upAccounts[actIndex] = action.payload.account;
            return {
                ...state,
                accounts: upAccounts,
            };

        case actionTypes.REMOVE_ACCOUNT:
            return {
                ...state,
                accounts: state.accounts.filter(
                    account => account.id !== action.payload.accountId
                ),
            };

        default:
            return state;
    }
};

export default reducer;
