import { Link } from 'react-router-dom';
import {
    Navbar,
    NavbarBrand,
    NavbarToggler,
    Collapse,
    Nav,
    NavbarText,
} from 'reactstrap';
import cl from 'classnames';
import { withRouter } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import * as ROUTES from '../constants/routes';
import LangSelector from './LangSelector';

const NavLink = ({ activeItem, route, label }) => (
    <li className="nav-item">
        <Link
            to={route}
            className={cl({ active: activeItem === route }, 'nav-link')}
        >
            {label}
        </Link>
    </li>
);

const MyNavbar = ({ activeItem, navBarOpen, toggleNavBar, handleLogout }) => {
    const { t } = useTranslation();

    return (
        <Navbar color="light" light expand="md">
            <NavbarBrand href="/">{t('APP_TITLE')}</NavbarBrand>

            <NavbarToggler onClick={() => toggleNavBar()} />

            <Collapse isOpen={navBarOpen} navbar>
                <Nav className="mr-auto" navbar>
                    <NavLink
                        activeItem={activeItem}
                        activeClassName="active"
                        route={ROUTES.ACCOUNTS}
                        label={t('NAVBAR.ACCOUNTS')}
                    />
                    <NavLink
                        activeItem={activeItem}
                        activeClassName="active"
                        route={ROUTES.TRANSACTIONS}
                        label={t('NAVBAR.TRANSACTIONS')}
                    />
                    <NavLink
                        activeItem={activeItem}
                        activeClassName="active"
                        route={ROUTES.CATEGORIES}
                        label={t('NAVBAR.CATEGORIES')}
                    />
                    <NavLink
                        activeItem={activeItem}
                        activeClassName="active"
                        route={ROUTES.REPORTS}
                        label={t('NAVBAR.REPORTS')}
                    />
                    <NavLink
                        activeItem={activeItem}
                        route={ROUTES.PROFILE}
                        label={t('NAVBAR.PROFILE')}
                    />
                </Nav>

                <LangSelector />

                <NavbarText onClick={handleLogout} className="cursor-pointer">
                    {t('NAVBAR.LOGOUT')}
                </NavbarText>
            </Collapse>
        </Navbar>
    );
};

export default withRouter(MyNavbar);
