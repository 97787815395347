import React from 'react';
import './Spinner.scss';

const Spinner = () => (
    <div className="spinner-container">
        <div className="d-flex flex-grow-1 justify-content-center align-items-center w-100 h-100">
            <div className="spinner-grow text-primary" role="status">
                <span className="sr-only">Cargando...</span>
            </div>
        </div>
    </div>
);

export {Spinner};
