const getAccountTypeName = (accountTypes, accountTypeId) => {
    if (accountTypes && accountTypes.length) {
        const account = accountTypes.find(acc => acc.id === accountTypeId);
        if (account) {
            return account.name;
        }
    }

    return '';
};

export default getAccountTypeName;
