import { toast } from 'react-toastify';

export const successNotification = message => {
    notification('success', message);
};

export const errorNotification = message => {
    notification('error', message);
};

const notification = (type, message) => {
    toast[type](message, {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    });
};
